import React, { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js/auto"
import { getTranslatedText as t } from "../../_locale"
import Check from "../../styles/icons/Check"
import { utils } from "../../_helpers/utils"
import { sessionActions } from "../../actions/session.actions"
import DisplayAnswersButton from "../partials/DisplayAnswersButton"
import DoughnutChart from "../partials/charts/DoughnutChart"
import BarChart from "../partials/charts/BarChart"
import EvaluationCard from "../partials/EvaluationCard"
import User from "../../styles/icons/User"
import Percent from "../../styles/icons/Percent"
import Users from "../../styles/icons/Users"
import AccordionTable from "./AccordionTable"
import ExamResultCard from "./ExamResultCard"
import CompareCard from "./CompareCard"
import SectionHeader from "./SectionHeader"

ChartJS.register(ArcElement, Tooltip, Legend)

const Result = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const { exam_code, session_id } = useParams()
    const { language } = useSelector((state) => state.language)
    const { session, result } = useSelector((state) => state.session)

    let sessionData = utils.objectHasLength(result) ? result : utils.objectHasLength(session) && session
    let sessionDate = sessionData.session_starts_at && new Date(sessionData.session_starts_at)
    let is_answer_show_date_in_progress = useMemo(
        () =>
            new Date() > new Date(sessionData.answer_show_start_time) &&
            new Date() < new Date(sessionData.answer_show_end_time),
        [sessionData]
    )

    useEffect(() => {
        if (!utils.objectHasLength(sessionData)) {
            dispatch(sessionActions.getSessionResult(exam_code, session_id))
        }
    }, [sessionData])

    const getScoreTable = () => {
        return (
            <div>
                <h5 className="mb-4"> {t("subjects")}</h5>
                <div className="bg-white p-3 p-sm-5 rounded">
                    <table className="table">
                        <AccordionTable sessionData={sessionData} />
                    </table>
                </div>
            </div>
        )
    }

    const getCharts = (examHasMultipleLessons, lessonHasPreviousSessions) => {
        if (!examHasMultipleLessons) {
            return (
                <div className="row d-flex mb-5 flex-column flex-lg-row">
                    <div className={`col-12 ${lessonHasPreviousSessions ? "col-lg-8" : ""}`}>
                        <div className={`${lessonHasPreviousSessions ? "mb-5" : ""} mb-lg-0 bg-white rounded`}>
                            <div className="p-5">
                                <div className="h-100">
                                    <h5 className="mb-4">{t("examResult")}</h5>
                                    <div className="row flex-column flex-md-row">
                                        <div className="col-12 col-lg-3 mt-5">
                                            <div className="row">
                                                <div className="col-4 col-lg-12">
                                                    {sessionData.success_rate && (
                                                        <EvaluationCard
                                                            text={t("successRate")}
                                                            value={sessionData.success_rate}
                                                            className="mb-5"
                                                            children={<Percent fill="#ff9f43" />}
                                                            childClassName="bg-light-orange"
                                                        />
                                                    )}
                                                </div>
                                                <div className="col-4 col-lg-12">
                                                    {sessionData.position && (
                                                        <EvaluationCard
                                                            text={t("position")}
                                                            value={sessionData.position}
                                                            className="mb-5"
                                                            children={<User stroke="#00cfe8" />}
                                                            childClassName="bg-light-blue"
                                                        />
                                                    )}
                                                </div>
                                                <div className="col-4 col-lg-12">
                                                    {sessionData.participants_count && (
                                                        <EvaluationCard
                                                            text={t("participation")}
                                                            value={sessionData.participants_count}
                                                            children={<Users fill="#7367f0" />}
                                                            childClassName="bg-light-purple"
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <ExamResultCard sessionData={sessionData} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {lessonHasPreviousSessions && (
                        <div className="col col-lg-4">
                            <div className="p-5 bg-white rounded ml-md-1">
                                <h5 className="mb-4">{t("progress")}</h5>
                                <div
                                    className="d-flex align-items-center justify-content-center"
                                    style={{ height: "250px", width: "100%" }}
                                >
                                    <CompareCard />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )
        } else {
            return (
                <div>
                    <div className="row">
                        <div className={`col-12 ${lessonHasPreviousSessions ? "col-lg-8" : ""} mb-5`}>
                            <div className={`mb-lg-0 bg-white rounded ${lessonHasPreviousSessions ? "mr-md-2" : ""}`}>
                                <div className="p-5">
                                    <div className="h-100">
                                        <h5 className="mb-4">{t("examResult")}</h5>
                                        <div className="row flex-column flex-md-row">
                                            <div className="col-12 col-lg-3 mt-5">
                                                <div className="row">
                                                    <div className="col-4 col-lg-12">
                                                        {sessionData.success_rate && (
                                                            <EvaluationCard
                                                                text={t("successRate")}
                                                                value={sessionData.success_rate}
                                                                className="mb-5"
                                                                children={<Percent fill="#ff9f43" />}
                                                                childClassName="bg-light-orange"
                                                            />
                                                        )}
                                                    </div>
                                                    <div className="col-4 col-lg-12">
                                                        {sessionData.position && (
                                                            <EvaluationCard
                                                                text={t("position")}
                                                                value={sessionData.position}
                                                                className="mb-5"
                                                                children={<User stroke="#00cfe8" />}
                                                                childClassName="bg-light-blue"
                                                            />
                                                        )}
                                                    </div>
                                                    <div className="col-4 col-lg-12">
                                                        {sessionData.participants_count && (
                                                            <EvaluationCard
                                                                text={t("participation")}
                                                                value={sessionData.participants_count}
                                                                children={<Users fill="#7367f0" />}
                                                                childClassName="bg-light-purple"
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            <ExamResultCard sessionData={sessionData} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {lessonHasPreviousSessions && (
                            <div className="col-12 col-lg-4 mb-5 mb-lg-0">
                                <div className="p-5 bg-white rounded">
                                    <h5 className="mb-4">{t("progress")}</h5>
                                    <div
                                        className="d-flex align-items-center justify-content-center"
                                        style={{ height: "250px", width: "100%" }}
                                    >
                                        <CompareCard />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="col px-0 mb-5">
                        {/* sort courses by name */}
                        {Object.values(sessionData.course_results)
                            .sort((a, b) => {
                                return a.name[language].localeCompare(b.name[language])
                            })
                            .map((course, index) => {
                                return (
                                    <div>
                                        <h5 className="mb-4">{course.name[language]}</h5>
                                        <div className="row">
                                            <div
                                                className={`col ${lessonHasPreviousSessions ? "col-lg-4" : "col-sm-6"}`}
                                            >
                                                <div className="p-5 bg-white rounded mb-5 mr-md-1">
                                                    <h5 className="mb-4">{t("lessonResults")}</h5>

                                                    <div
                                                        className="d-flex justify-content-center"
                                                        style={{ height: "250px", width: "100%" }}
                                                    >
                                                        <BarChart
                                                            dataSet={[
                                                                course.true_answers,
                                                                course.false_answers,
                                                                course.empty_answers
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                className={`col ${lessonHasPreviousSessions ? "col-lg-4" : "col-sm-6"}`}
                                            >
                                                <div className="p-5 bg-white rounded mb-5 ml-md-1">
                                                    <h5 className="mb-4">{t("score")}</h5>
                                                    <div
                                                        className="d-flex align-items-center justify-content-center"
                                                        style={{ height: "250px", width: "100%" }}
                                                    >
                                                        <DoughnutChart data={course} />
                                                    </div>
                                                </div>
                                            </div>

                                            {lessonHasPreviousSessions && (
                                                <div className="col col-lg-4">
                                                    <div className="p-5 bg-white rounded ml-md-1 mb-5">
                                                        <h5 className="mb-4">{t("progress")}</h5>
                                                        <div
                                                            className="d-flex align-items-center justify-content-center"
                                                            style={{ height: "250px", width: "100%" }}
                                                        >
                                                            <CompareCard />
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )
                            })}
                    </div>
                </div>
            )
        }
    }

    const getResults = () => {
        let examHasMultipleLessons = sessionData.course_results && Object.keys(sessionData.course_results).length > 1
        // let lessonHasPreviousSessions = sessionData.repeatable
        let lessonHasPreviousSessions = false
        switch (sessionData.score_status) {
            case "show":
                return (
                    <>
                        {getCharts(examHasMultipleLessons, lessonHasPreviousSessions)}
                        {getScoreTable()}
                    </>
                )
            case "timed":
                if (sessionData && is_answer_show_date_in_progress) {
                    return (
                        <>
                            {getCharts(examHasMultipleLessons, lessonHasPreviousSessions)}
                            {getScoreTable()}
                        </>
                    )
                } else {
                    let dateBetween = `${utils.getLocaleDateTime(sessionData.answer_show_start_time)} ${t(
                        "and"
                    )} ${utils.getLocaleDateTime(sessionData.answer_show_end_time)}`
                    return (
                        <p className="px-3 text-center timed">
                            <i className="fal fa-exclamation-circle fa-lg mr-3"></i>
                            {utils.replaceWithBold(
                                t("resultsWillBeDisplayed").replace(":field_name", dateBetween),
                                dateBetween
                            )}
                        </p>
                    )
                }
            default:
                return
        }
    }

    return (
        <>
            {utils.objectHasLength(sessionData) && (
                <div className="result">
                    <div className="row pb-3 p-sm-4 p-lg-5 d-flex flex-column no-margin">
                        <div className="col pt-3 pt-sm-0">
                            {utils.getFirstPath() === "exam-end" && (
                                <>
                                    <div className="d-flex justify-content-center mb-5">
                                        <Check width="10rem" fill="#ffaa40" />
                                    </div>
                                    <h3 className="mb-4 text-center">{t("examSaved")}</h3>
                                </>
                            )}
                        </div>

                        <SectionHeader
                            primaryData={sessionData.name ?? sessionData.name[language]}
                            secondaryData={
                                sessionData.course_results && sessionData.course_results.length === 1
                                    ? sessionData.course_results[0].name[language]
                                    : null
                            }
                            tertiaryData={
                                sessionDate && t("examStartDate") + ": " + utils.getLocaleDateTime(sessionDate)
                            }
                            classname={
                                utils.getFirstPath() === "exam-end" || sessionData.score_status === "timed"
                                    ? "justify-content-center"
                                    : "justify-content-start"
                            }
                        />
                        <div className="col px-0">{getResults()}</div>
                        <div className="col px-0 ">
                            <div className="d-flex align-items-center justify-content-center mb-4 mt-5">
                                {sessionData.score_status === "show" && sessionData.answer_status && (
                                    <div>
                                        <DisplayAnswersButton
                                            exam_code={sessionData.exam_code}
                                            session_id={sessionData.session_id}
                                            history={history}
                                        />
                                    </div>
                                )}

                                {sessionData.score_status === "timed" &&
                                    sessionData.answer_status &&
                                    is_answer_show_date_in_progress && (
                                        <div>
                                            <DisplayAnswersButton
                                                exam_code={sessionData.exam_code}
                                                session_id={sessionData.session_id}
                                                history={history}
                                            />
                                        </div>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default Result
