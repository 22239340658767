import React from "react"
import { useHistory } from "react-router-dom/cjs/react-router-dom"
import { getTranslatedText as t } from "../../_locale"

const ResultsButton = (props) => {
    const history = useHistory()
    const { exam_code, session_id } = props
    return (
        <div className="d-flex align-items-center justify-content-end">
            <button
                className="btn box-btn style-3"
                onClick={() => history.push(`/results/${exam_code}/${session_id}`)}
            >
                {t("results")} <i className="fa fa-angle-right ml-2"></i>
            </button>
        </div>
    )
}

export default ResultsButton
