const base = {
    home: "Home",
    results: "Results",
    examStartDate: "Exam Start Date",
    examEndDate: "Exam End Date",
    activeExams: "Active Exams",
    noActiveExam: "No Active Exam",
    question: "Question",
    noQuestion: "No Question",
    hour: "Hour",
    minutes: "Minutes",
    seconds: "Seconds",
    start: "Start",
    examWithCode: "Start the exam with code",
    enterExamCodeWarning: "Please fill out the exam code to start the exam",
    alreadyFinished: "You have already finished this exam.",
    examCode: "Exam Code",
    upComingExams: "Upcoming Exams",
    noUpComingExams: "No Upcoming Exams",
    noLaunchedExam: "No Launched Exams",
    turkish: "Türkçe",
    english: "English",
    logout: "Logout",
    exam: "Exam",
    all: "All",
    empty: "Empty",
    favorited: "Favorited",
    answered: "Answered",
    no: "No",
    goToQuestion: "Go to question",
    save: "Save",
    clear: "Clear",
    calendar: "Calendar",
    allLessons: "All Lessons",
    finishExam: "Finish Exam",
    typeAnswer: "Type Your Answer Here..",
    about: "About",
    examIsOver: "The Exam Is Over",
    examSummary: "Exam Summary",
    totalQuestion: "Total Question",
    answers: "Answers",
    correctAnswers: "Correct answers",
    correctAnswer: "Correct answer",
    wrongAnswers: "Wrong answers",
    emptyQuestion: "Empty Question",
    afterReview: " Question to Enter Grade After Teacher Review",
    back: "Back",
    previousQuestion: "Previous Question",
    nextQuestion: "Next Question",
    notFound: "Page not found",
    maintanance: "We're down for maintenance",
    emptyQuestionCount: "Number of questions left blank : ",
    warning: "WARNING",
    warningMessage: "Are you sure you want to finish the exam?",
    control: "Control",

    ///////////////////////////////////////
    //AUTH

    welcome: "Welcome",
    loginWithAccount: "Please login to your account.",
    login: "Login",
    responsive: "Apitwist Exam is Mobile-Friendly",
    webIntro: "You can follow the ApiTwist Exam from any device, see the statistics and solve the exams again.",
    email: "E-mail",
    usernameOrEmail: "Username or Email",
    emailPlaceholder: "Please enter your email.",
    password: "Password",
    forgotPassword: "Forgot Password",
    notLoggedIn: "Not Logged In",
    credentialError: "Incorrect email or password.",
    tryAgain: "Please try again.",
    detectedLoginDifferentBrowser: "Detected login from different browser.",
    //////////////////////////////////////

    //////////////////////////////////////
    //VALIDATION

    VAL_REQUIRED: ":field_name field is required.",
    VAL_MIN_CHAR: ":field_name must be at least :min :val_max characters.",
    VAL_MAX_CHAR: "up to :max",
    VAL_MAIL: "The mail must be a valid email address.",
    //////////////////////////////////////

    //////////////////////////////////////
    //HOME

    min: "min",
    launchedExams: "Launched Exams",
    continue: "Continue",
    repeatableExam: "Repeatable",
    nonRepeatableExam: "Non Repeatable",
    //////////////////////////////////////

    //SESSION

    examEndedDueToTimeIsUp: "Exam ended due to time is up.",
    examWillFinishSoon: "Last :field_name seconds! After :field_name seconds the exam will close automatically.",
    leaveExamWarning: "Are you sure you want to leave the exam?",
    controlAnswersInResultsPage: "Check your answers on the :field_name page.",
    controlAnswers: "Check your answers.",
    resultsWillBeDisplayed: `Results will be available between :field_name`,
    and: "and",
    examSaved: "Exam has been saved succesfully.",
    noQuestionsTo: "You have not marked any questions as 'question to :field_name' yet.",
    answeredAllQuestions: "You have answered all questions.",
    score: "Score",
    true: "True",
    false: "False",
    show: "Show",
    lessons: "Lessons",
    displayAnswers: "See Answers",
    attempt: "Attempt",
    displayResults: "See Results",
    wrong: "Wrong",
    correct: "Correct",
    multipleAttempts: "Multiple Attempts",
    multipleChoice: "You can mark multiple choices.",
    examCompleted: "Exam Completed",
    startExam: "Start Exam",
    instructions: "Instructions",
    description: "Description",
    noInstructions: "No Instructions.",
    noDescription: "No Description.",
    groupQuestion: "Answer questions :field_name according to the instructions above.",
    participation: "Participation",
    lessonResults: "Lesson Results",
    position: "Position",
    successRate: "Success Rate",
    t: "T",
    f: "F",
    e: "E",
    progress: "Progress",
    subjects: "Subjects",
    examResult: "Exam Result",
    noExamResult: "Join an exam to display result.",
    students: "Students",
    questions: "Questions",
    examTime: "Exam Time",
    average: "Average",
    detailReport: "Detail Report",
}
export default base
