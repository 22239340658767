import React from "react"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"

const TooltipContainer = ({ children, icon }) => {
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {icon && icon.map((i, key) => <span key={key} className="tooltip-icon">{i}</span>)}
        </Tooltip>
    )
    return (
        <OverlayTrigger placement="top" delay={{ show: 250, hide: 500 }} overlay={renderTooltip}>
            {children}
        </OverlayTrigger>
    )
}

export default TooltipContainer
