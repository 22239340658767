import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { userActions } from "../../actions/user.actions"
import { utils } from "../../_helpers/utils"
import { getTranslatedText as t } from "../../_locale"
import CreateFormElement from "../partials/CreateFormElement"

const LoginForm = ({ view, continueParam }) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const user = useSelector((state) => state.user)
    const [values, setValues] = useState({ email: "", password: "" })
    const [formErrors, setFormErrors] = useState({})

    const inputTypes = [
        {
            id: 1,
            name: "email",
            type: "text",
            element: "input",
            placeholder: t("emailPlaceholder"),
            label: t("usernameOrEmail")
        },
        {
            id: 2,
            name: "password",
            type: "password",
            element: "input",
            placeholder: t("password"),
            label: t("password")
        }
    ]

    const formValues = {
        email: {
            name: "Username or Email",
            input: values.email,
            validations: {
                required: true
            }
        },
        password: {
            name: "Password",
            input: values.password,
            validations: {
                required: true,
                min: 5,
                max: 23
            }
        }
    }

    const onChange = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        })

        utils.validateClassForm({
            key: e.target.name,
            value: e.target.value,
            formErrors: formErrors,
            setFormErrors: setFormErrors,
            formValues: formValues
        })
    }
    const loginHandler = (e) => {
        e.preventDefault()
        if (utils.validateClassForm({ setFormErrors, formValues })) {
            dispatch(userActions.login(values.email, values.password, continueParam, history))
        }
    }

    return (
        <div
            className={view === "login" ? "tab-pane fade show active" : "tab-pane fade"}
            id="nav-login"
            role="tabpanel"
            aria-labelledby="nav-login-tab"
        >
            {!user.loading && user.error && <div className="alert alert-danger login-error">{user.error}</div>}
            <form onSubmit={loginHandler}>
                {/* TODO: CHECK ERROR CASE */}
                {user.error && <p className="form-invalid text-danger">{user.error.error}</p>}

                {inputTypes.map((inputType) => (
                    <CreateFormElement
                        key={inputType.id}
                        inputType={inputType}
                        value={values[inputType.name]}
                        onChange={onChange}
                        formErrors={formErrors}
                    />
                ))}
                <button type="submit" className="btn box-btn mt-4 mw-100" disabled={user.loading ? true : false}>
                    {t("login")}
                </button>
                {/* <div className="form-text-link">
                    <a href="/login" title="Şifre Hatırlat">
                        {t("forgotPassword")}
                    </a>
                </div> */}
            </form>
        </div>
    )
}

export default LoginForm
