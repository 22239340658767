import { getTranslatedText as t } from "../_locale"
export const ANS_OPTIONS = ["A", "B", "C", "D", "E", "F", "G"]

export const TRUE_FALSE_OPTIONS = [
    { key: "T", value: "True" },
    { key: "F", value: "False" }
]

export const FILTER_OPTIONS = {
    ALL: t("all"),
    FAVORITE: t("control"),
    EMPTY: t("empty"),
    FULL: t("answered"),
    CONTROL: t("control"),
    WRONG: t("wrong")
}
