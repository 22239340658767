import React from "react"
import { ANS_OPTIONS, TRUE_FALSE_OPTIONS } from "../../_helpers/constants"
import { useDispatch } from "react-redux"
import { sessionActions } from "../../actions/session.actions"
import { utils } from "../../_helpers/utils"

const Answers = ({ question }) => {
    const dispatch = useDispatch()

    const getAnswer = () => {
        switch (question.question_type_id) {
            case 3: // ******  True - False  ******
                return TRUE_FALSE_OPTIONS.map((option, key) => {
                    return (
                        <label
                            key={key}
                            className={
                                utils.arrayHasLength(question.student_answer) &&
                                question.student_answer[0].answer === option.key
                                    ? question.questionStatus !== undefined
                                        ? question.questionStatus
                                            ? "correct"
                                            : "wrong"
                                        : "active"
                                    : ""
                            }
                        >
                            <input
                                disabled={utils.checkPageInResult()}
                                type="radio"
                                value={"radio" + option.key}
                                onClick={() => {
                                    dispatch(
                                        sessionActions.answerQuestion(
                                            question.id,
                                            question.answers[0].id,
                                            option.key,
                                            question.name,
                                            question.question_type_id
                                        )
                                    )
                                }}
                            />
                            <span className="mcq-option">{option.key}</span>
                            <div className="d-flex w-100 justify-content-between">{option.value} </div>
                        </label>
                    )
                })
            case 2: // ****** Çoklu seçmeli ****** //
                return question.answers.map((answer, answer_key) => {
                    return (
                        <label
                            htmlFor={"radio" + answer.id}
                            key={answer.id}
                            className={
                                utils.arrayHasLength(question.student_answer) &&
                                question.student_answer.some((perAnswer) => perAnswer.answer_id === answer.id)
                                    ? question.questionStatus !== undefined
                                        ? question.questionStatus
                                            ? "correct"
                                            : "wrong"
                                        : "active"
                                    : ""
                            }
                        >
                            <input
                                disabled={utils.checkPageInResult()}
                                type="radio"
                                name={"radio" + answer.id}
                                id={"radio" + answer.id}
                                value={"radio" + answer.id}
                                onClick={() => {
                                    dispatch(
                                        sessionActions.answerQuestion(
                                            question.id,
                                            answer.id,
                                            ANS_OPTIONS[answer_key],
                                            question.name,
                                            question.question_type_id
                                        )
                                    )
                                }}
                            />
                            <span className="mcq-option multiple-answer">{ANS_OPTIONS[answer_key]}</span>
                            <div />
                            <div className="d-flex w-100 justify-content-between ">
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: utils.sanitizeHtml(answer.answer)
                                    }}
                                ></div>
                            </div>
                        </label>
                    )
                })
            case 1: // ******  Çoktan Seçmeli  ******
            default:
                return question.answers.map((answer, answer_key) => {
                    return (
                        <label
                            htmlFor={"radio" + answer.id}
                            key={answer.id}
                            className={
                                utils.arrayHasLength(question.student_answer) &&
                                question.student_answer[0].answer_id === answer.id
                                    ? question.questionStatus !== undefined
                                        ? question.questionStatus
                                            ? "correct"
                                            : "wrong"
                                        : "active"
                                    : ""
                            }
                        >
                            <input
                                disabled={utils.checkPageInResult()}
                                type="radio"
                                name={"radio" + answer.id}
                                id={"radio" + answer.id}
                                value={"radio" + answer.id}
                                onClick={() => {
                                    dispatch(
                                        sessionActions.answerQuestion(
                                            question.id,
                                            answer.id,
                                            ANS_OPTIONS[answer_key],
                                            question.name,
                                            question.question_type_id
                                        )
                                    )
                                }}
                            />
                            <span className="mcq-option">{ANS_OPTIONS[answer_key]}</span>

                            <div className="d-flex w-100 justify-content-between ">
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: utils.sanitizeHtml(answer.answer)
                                    }}
                                ></div>
                            </div>
                        </label>
                    )
                })
        }
    }

    return <div className="answer-optons">{getAnswer()}</div>
}

export default Answers
