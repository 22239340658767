import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { getTranslatedText as t } from "../../_locale"

const ExamAccordionTable = ({ results, name, setStudentDetail }) => {
    const { language } = useSelector((state) => state.language)
    return (
        <>
            {Object.values(results)
                .sort((a, b) => {
                    return a.course_name[language].localeCompare(b.course_name[language])
                })
                .map((course, courseIndex) => (
                    <React.Fragment key={courseIndex}>
                        <thead id="accordion course-result-table">
                            <tr>
                                <td>
                                    <h5>{name}</h5>
                                </td>
                                <td colSpan={4} className="font-weight-bold text-center merged-column">
                                    <b> {course.course_name[language]}</b>
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td className="text-success font-weight-bold text-center">{t("t")}</td>
                                <td className="text-danger font-weight-bold text-center">{t("f")}</td>
                                <td className="text-primary font-weight-bold text-center">{t("e")}</td>
                                <td className="font-weight-bold text-center">{t("score")}</td>
                            </tr>
                        </thead>

                        <tbody className="table-row" id={course.course_name[language] + courseIndex}>
                            {Object.values(course.students)
                                .sort((a, b) => {
                                    return b.result.score - a.result.score
                                })
                                .map((studentResult, studentResultIndex) => (
                                    <React.Fragment key={studentResultIndex}>
                                        <tr
                                            id={course.course_name[language] + courseIndex}
                                            className="table-row cursor-pointer"
                                            onClick={() => setStudentDetail(studentResult)}
                                        >
                                            <td className="text-left pl-3">
                                                {studentResult.student.name} {studentResult.student.surname}
                                            </td>
                                            <td className="text-center">
                                                <b className="text-success">{studentResult.result.true_answers}</b>
                                            </td>
                                            <td className="text-center">
                                                <b className="text-danger">{studentResult.result.false_answers}</b>
                                            </td>
                                            <td className="text-center">
                                                <b className="text-primary">{studentResult.result.empty_answers}</b>
                                            </td>
                                            <td className="text-center">
                                                <b>{studentResult.result.score}</b>
                                            </td>
                                        </tr>

                                        {/* {studentResult.result.subjects && (
                                            <div className="pl-5 row">
                                                {Object.values(studentResult.result.subjects)
                                                    .sort((a, b) => {
                                                        return a.name[language].localeCompare(b.name[language])
                                                    })
                                                    .map((subject, subjectIndex) => (
                                                        <div
                                                            id={`collapse ${
                                                                studentResult.student.name +
                                                                courseIndex +
                                                                studentResultIndex
                                                            }`}
                                                            className="collapse subjects-row col-12 row"
                                                            key={subjectIndex}
                                                        >
                                                            <div className="text-left c-text-secondary col-6">
                                                                {subject.name[language]}
                                                            </div>
                                                            <div className="col-1">
                                                                <b className="text-success">{subject.true_answers}</b>
                                                            </div>
                                                            <div className="col-1">
                                                                <b className="text-danger">{subject.false_answers}</b>
                                                            </div>
                                                            <div className="col-1">
                                                                <b className="text-primary">{subject.empty_answers}</b>
                                                            </div>
                                                            <div className="col-1">
                                                                <b>
                                                                    {Math.round(
                                                                        (subject.true_answers * 100) /
                                                                            (subject.true_answers +
                                                                                subject.false_answers +
                                                                                subject.empty_answers)
                                                                    )}
                                                                </b>
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        )} */}
                                    </React.Fragment>
                                ))}

                            {course.true_answers && course.empty_answers && course.false_answers && course.score && (
                                <tr className="table-row cursor-pointer">
                                    <td className="text-left pl-3">{t("average")}</td>
                                    <td className="text-center">
                                        <b className="text-success">{course.true_answers}</b>
                                    </td>
                                    <td className="text-center">
                                        <b className="text-danger">{course.false_answers}</b>
                                    </td>
                                    <td className="text-center">
                                        <b className="text-primary">{course.empty_answers}</b>
                                    </td>
                                    <td className="text-center">
                                        <b>{course.score}</b>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </React.Fragment>
                ))}
        </>
    )
}

export default ExamAccordionTable
