import React from "react"
import { getTranslatedText as t } from "../../_locale"

const FooterBar = () => {
    return (
        <footer className="footer-text bdr-top">
            {t("exam")} © {new Date().getFullYear()} by ApiTwist &nbsp;
        </footer>
    )
}

export default FooterBar
