import { examConstants } from "../_constants/exam.constants"
import { spinner } from "./spinner"
import { examServices } from "../services/exam.services"

export const examActions = {
    getAll,
    getResult
}

function getAll(continueParam, history) {
    return (dispatch) => {
        dispatch(spinner(true))
        examServices
            .getAll()
            .then((data) => {
                dispatch(success(data))
                dispatch(spinner(false))

                const isAssignedExam = data?.exams?.some((exam) => exam.exam_code === continueParam)

                continueParam && history && isAssignedExam && history.push(`/exam-guide/${continueParam}`)
            })
            .catch((err) => {
                dispatch(spinner(false))
                dispatch(failure(err))
            })
    }

    function success(data) {
        return {
            type: examConstants.GET_ALL_SUCCESS,
            data
        }
    }

    function failure(error) {
        return {
            type: examConstants.GET_ALL_FAILURE,
            error
        }
    }
}

function getResult(exam_code) {
    return (dispatch) => {
        dispatch(request())
        dispatch(spinner(true))
        examServices
            .getResult(exam_code)
            .then((data) => {
                dispatch(success(data))
                dispatch(spinner(false))
            })
            .catch((err) => {
                dispatch(spinner(false))
                dispatch(failure(err))
            })
    }
    function request() {
        return { type: examConstants.GET_RESULT_REQUEST }
    }

    function success(data) {
        return {
            type: examConstants.GET_RESULT_SUCCESS,
            data
        }
    }

    function failure(error) {
        return {
            type: examConstants.GET_RESULT_FAILURE,
            error
        }
    }
}


