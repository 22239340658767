/* eslint-disable import/no-anonymous-default-export */
import { examConstants } from "../_constants/exam.constants"

const initialState = {
    activeExams: [],
    upcomingExams: [],
    result : {}
}

export default (state = initialState, action) => {
    switch (action.type) {
        case examConstants.GET_ALL_SUCCESS:
            return {
                ...state,
                activeExams: action.data.exams,
                upcomingExams: action.data.upcoming_exams
            }
        case examConstants.GET_ALL_FAILURE:
            return {
                error: action.error
            }
        case examConstants.GET_RESULT_REQUEST:
            return {
                ...state,
                result: {}
            }
        case examConstants.GET_RESULT_SUCCESS:
            return {
                ...state,
                result: action.data
            }
        default:
            return state
    }
}
